.ImageButton {
    transition: ease-in 300ms;
}

.ImageButton:hover {
    opacity: 0.7;
}

.ImageButton:active {
    transition: 50ms;
    opacity: 0.3;
}
